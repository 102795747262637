// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { Route, Routes } from 'react-router-dom';
import Home from '../../views/Home';
import Layout from "../Layout";
import Login from "../../views/form/Login";
import Register from "../../views/form/Register";
import ResetPassword from "../../views/ResetPassword";
import ForgotPassword from "../../views/ForgotPassword";
import ViewContainer from '../ViewContainer';
import Cookies from 'universal-cookie';
import Profile from '../../views/Profile';
import AdminSettings from '../../views/AdminSettings';
import Settings from '../../views/Settings';
import Users from '../../views/Users';
import EditUser from '../../views/form/EditUser';
import NewTravel from '../../views/form/NewTravel';
import Travels from '../../views/Travels';
import TravelDetail from '../../views/TravelDetail';
import DriverHome from '../../views/DriverHome';
import AuthChecker from './AuthChecker';
import AppData from './AppData';
import CheckoutResult from '../../views/stripe/CheckoutResult';
import TravelsAdmin from '../../views/TravelsAdmin';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = () => {
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  const getHomeComponent = () => {
    switch (user.active_role) {
      case 1: return <TravelsAdmin />;
      case 4: return <Travels />;
      case 2: return <Home />;
      case 3: return <DriverHome />;
      default: return <Home />;
    }
  }

  const routes = [
    { path: '/', component: getHomeComponent(), title: 'Accueil' },
    { role: [2, 4], path: '/trajets', component: <Travels mission_user_get={user.user_id} />, title: 'Trajets' },
    { role: [1, 2], path: '/trajets/modifier/:uuid', component: <NewTravel edit={true} />, title: 'Modifier le trajet' },
    { role: [1, 2, 3, 4], path: '/trajets/:uuid', component: <TravelDetail />, title: 'Détail du trajet' },
    { role: [1, 3], path: '/mes-missions', component: <Travels proposal_user_get={user.user_id} />, title: 'Mes missions' },
    { role: [1, 2], path: '/nouveau-trajet', component: <NewTravel />, title: 'Nouveau trajet' },
    { role: [1, 3], path: '/nouvelles-courses', component: <Travels status={1} canPostulate={true} />, title: 'Nouvelles courses' },
    { path: '/profil', component: <Profile />, title: 'Profil' },
    { path: '/reglages', component: [1, 4].includes(user.active_role) ? <AdminSettings /> : <Settings />, title: 'Réglages' },
    { role: [1, 4], path: '/admin/utilisateurs', component: <Users />, title: 'Liste des utilisateurs' },
    { role: [1], path: '/admin/utilisateurs/ajouter', component: <Register admin={true} />, title: 'Créer un utilisateur' },
    { role: [1, 4], path: '/admin/utilisateurs/:uuid', component: <EditUser />, title: 'Modifier un utilisateur' },
    { role: [1, 4], path: '/admin/reglages', component: <Home />, title: "Réglages de l'application" },
    { path: '/resultat-paiement', component: <CheckoutResult />, title: "Résultat du paiement" },
  ]

  // 
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return <Routes>
    <Route path="/connexion" element={<Login />} />
    <Route path="/inscription" element={<Register />} />
    <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
    <Route path="/reinitialiser-le-mot-de-passe/:token" element={<ResetPassword />} />
    <Route element={<AuthChecker />}>
      <Route element={<AppData />}>
        <Route path="/" element={<Layout />}>
          {routes.map((route) => {
            if ((route.role && route.role.includes(user.active_role)) || !route.hasOwnProperty('role')) {
              return <Route key={route.path} path={route.path} element={route.title ? <ViewContainer key={route.path} title={route.title}>{route.component}</ViewContainer> : route.component} />
            }
            return null;
          })}
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<Login />} />
  </Routes >
};

export default Router